// LoggingService.js
import { useAuthStore } from "@/stores/authStore"
import { v4 as uuidv4 } from "uuid"

export const EventTypes = {
	CLICK: "click",
	NAVIGATION: "navigation",
	ERROR: "error",
	PERFORMANCE: "performance",
	SCROLL: "scroll",
	FORM_SUBMIT: "form_submit",
	// Add more event types hereas needed
}

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL

class LoggingService {
	constructor() {
		if (!LoggingService.instance) {
			this.logs = []
			this.sessionId = this.getSessionId()
			this.sendLogs = this.sendLogs.bind(this)
			this.flushLogs = this.flushLogs.bind(this)
			this.persistLogs = this.persistLogs.bind(this)
			this.loadPersistedLogs = this.loadPersistedLogs.bind(this)
			this.startLogInterval()
			window.addEventListener("beforeunload", this.flushLogs)
			this.loadPersistedLogs()
			LoggingService.instance = this
			this.log = true
		}
		return LoggingService.instance
	}

	// Generate or retrieve session ID
	getSessionId() {
		let sessionId = localStorage.getItem("sessionId")
		if (!sessionId) {
			sessionId = uuidv4()
			localStorage.setItem("sessionId", sessionId)
		}
		return sessionId
	}

	// Start the interval to send logs every X minutes
	startLogInterval() {
		// Send logs every 5 minutes (300,000 milliseconds)
		this.logInterval = setInterval(this.sendLogs, 60000)
	}

	// Log an event with a unified structure
	logEvent(eventType, action, details = {}) {
		if (!this.log) return
		const authStore = useAuthStore()
		const logEntry = {
			user_id: authStore.user?.id || 0,
			session_id: this.sessionId,
			event_type: eventType,
			action: action,
			details: details,
			device_info: navigator.userAgent,
		}

		this.logs.push(logEntry)
	}

	// Send logs to the backend
	async sendLogs() {
		if (this.logs.length === 0) return

		const logsToSend = [...this.logs]
		this.logs = []

		try {
			const response = await fetch(
				`${SUPABASE_URL}/functions/v1/logUserActivity`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ logs: logsToSend }),
					keepalive: true,
				}
			)

			if (!response.ok) {
				throw new Error(`Error sending logs: ${response.statusText}`)
			}

			console.log("Logs sent successfully at interval.")
		} catch (error) {
			console.error("Error sending logs:", error)
			// Persist logs to localStorage on error
			this.persistLogs(logsToSend)
		}
	}

	// Flush logs on app exit
	flushLogs() {
		if (this.logs.length === 0) return

		const logsToSend = [...this.logs]
		this.logs = []

		try {
			const payload = JSON.stringify({ logs: logsToSend })
			const blob = new Blob([payload], { type: "application/json" })

			const success = navigator.sendBeacon(
				`${SUPABASE_URL}/functions/v1/logUserActivity`,
				blob
			)

			if (!success) {
				// If sendBeacon fails, persist logs to localStorage
				this.persistLogs(logsToSend)
			} else {
				console.log("Logs sent successfully on exit.")
			}
		} catch (error) {
			console.error("Error flushing logs:", error)
			// Persist logs to localStorage on error
			this.persistLogs(logsToSend)
		}
	}

	// Persist unsent logs to localStorage
	persistLogs(logs) {
		const existingLogs = JSON.parse(localStorage.getItem("unsentLogs")) || []
		const combinedLogs = existingLogs.concat(logs)

		// Limit to the most recent 100 logs
		const maxLogs = 100
		if (combinedLogs.length > maxLogs) {
			combinedLogs.splice(0, combinedLogs.length - maxLogs)
		}

		localStorage.setItem("unsentLogs", JSON.stringify(combinedLogs))
	}

	// Load persisted logs on startup
	loadPersistedLogs() {
		const persistedLogs = JSON.parse(localStorage.getItem("unsentLogs"))
		if (persistedLogs && persistedLogs.length > 0) {
			this.logs = this.logs.concat(persistedLogs)
			localStorage.removeItem("unsentLogs")
			// Send the persisted logs immediately
			this.sendLogs()
		}
	}

	// New method for immediate logging
	async logImmediately(eventType, action, details = {}) {
		const authStore = useAuthStore()
		const logEntry = {
			user_id: authStore.user?.id || "anonymous",
			session_id: this.sessionId,
			event_type: eventType,
			action: action,
			details: details,
			device_info: navigator.userAgent,
		}

		try {
			const response = await fetch(
				`${SUPABASE_URL}/functions/v1/logUserActivity`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ logs: [logEntry] }),
				}
			)

			if (!response.ok) {
				throw new Error(`Error sending immediate log: ${response.statusText}`)
			}

			console.log("Immediate log sent successfully.")
		} catch (error) {
			console.error("Error sending immediate log:", error)
			// Fallback to persisting the log
			this.persistLogs([logEntry])
		}
	}
}

const instance = new LoggingService()
export default instance
