<script setup>
import { onMounted, ref, computed, watch } from "vue"
import { storeToRefs } from "pinia"
import { useI18n } from "@/composables/useI18n"
import { useAuthStore } from "@/stores/authStore"
import { useRouter } from "vue-router"

const { t, currentLocale } = useI18n()

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const navs = ref([])
const router = useRouter()

const currentRoute = computed(() => router.currentRoute.value.path)

function navigateTo(path) {
	router.push(path)
}

function isActive(path) {
	return currentRoute.value.startsWith(path)
}

onMounted(() => {
	fetchNavs()
})

watch(
	currentLocale,
	() => {
		console.log(currentLocale.value)
		fetchNavs()
	},
	{ immediate: true }
)

function fetchNavs() {
	if (user.value?.role == "seller") {
		navs.value = [
			{
				label: t("nav.home"),
				icon: "pi pi-fw pi-home",
				to: "/seller/dashboard",
			},
			{
				label: t("nav.products"),
				icon: "pi pi-fw pi-box",
				to: "/seller/products",
			},
			{
				label: t("nav.orders"),
				icon: "pi pi-fw pi-book",
				to: "/seller/orders",
			},
			// {
			// 	label: t("nav.agents"),
			// 	icon: "pi pi-fw pi-users",
			// 	to: "/seller/agents",
			// },
			{
				label: t("nav.profile"),
				icon: "pi pi-fw pi-user",
				to: "/seller/profile",
			},
		]
	} else if (user.value?.role == "agent") {
		navs.value = [
			{
				label: t("nav.home"),
				icon: "pi pi-fw pi-home",
				to: "/agent/dashboard",
			},
			{ label: t("nav.orders"), icon: "pi pi-fw pi-book", to: "/agent/orders" },
			{
				label: t("nav.profile"),
				icon: "pi pi-fw pi-user",
				to: "/agent/profile",
			},
		]
	} else if (user.value?.role == "courier") {
		navs.value = [
			{
				label: t("nav.home"),
				icon: "pi pi-fw pi-home",
				to: "/courier/dashboard",
			},
			{
				label: t("nav.route"),
				icon: "pi pi-fw pi-directions",
				to: "/courier/deliveryroute",
			},
			{
				label: t("nav.completed"),
				icon: "pi pi-fw pi-check-circle",
				to: "/courier/completed",
			},
			{
				label: t("nav.profile"),
				icon: "pi pi-fw pi-user",
				to: "/courier/profile",
			},
		]
	} else if (user.value?.role == "admin") {
		navs.value = [
			{
				label: t("nav.home"),
				icon: "pi pi-fw pi-home",
				to: "/admin/dashboard",
			},
			{ label: t("nav.users"), icon: "pi pi-fw pi-users", to: "/admin/users" },
			{
				label: t("nav.orders"),
				icon: "pi pi-fw pi-chart-bar",
				to: "/admin/orders",
			},
			{
				label: t("nav.products"),
				icon: "pi pi-fw pi-box",
				to: "/admin/products",
			},
			{
				label: t("nav.profile"),
				icon: "pi pi-fw pi-cog",
				to: "/admin/profile",
			},
		]
	}
}
</script>

<template>
	<div class="layout-footer">
		<div class="footer-nav">
			<Button
				v-for="(nav, index) of navs"
				:key="index"
				:class="[
					'p-button-text p-button-plain minimal-button',
					{ 'active-nav': isActive(nav.to) },
				]"
				@click="navigateTo(nav.to)"
			>
				<template #default>
					<i :class="nav.icon" class="nav-icon"></i>
					<span class="nav-label">{{ nav.label }}</span>
				</template>
			</Button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.footer-nav {
	display: flex;
	justify-content: space-around;
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #f8f9fa;
}

.minimal-button {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #333;
	transition:
		color 0.3s ease,
		font-weight 0.3s ease;
	font-weight: 500;
}

.active-nav {
	color: var(--primary-color, #007bff) !important;
	font-weight: bold;
	border-bottom: 4px solid var(--primary-color, #007bff) !important;
}

.nav-icon {
	font-size: 20px;
}

.nav-label {
	font-size: 14px;
}
</style>
